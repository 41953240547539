import React from "react"
import { Link } from "gatsby"

import Stack from "@bedrock-layout/stack"
import PadBox from "@bedrock-layout/padbox"
import InlineCluster from "@bedrock-layout/inline-cluster"
import { SplitSwitcher, ColumnsSwitcher } from "@bedrock-layout/switcher"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import hex from "../images/Arik-hex.png"
import web from "../images/dev-icon.png"
import logo from "../images/logo-icon.png"
import print from "../images/brochure-icon.png"

const ColorBlock = styled(PadBox)`
  background: ${props => props.bgColor};
  color: ${props => props.color};
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 750px;
  justify-content: center;
  &:first-of-type {
    height: calc(100vh - 77px);
  }
  &:last-of-type {
    height: 100%;
    min-height: calc(100vh - 60px);
  }
`
const BlockContent = styled(PadBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-shadow: 3px 3px 4px rgba(20, 20, 20, 0.4);
`

const BlockImageContent = styled(PadBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: 936px) {
    order: 1;
  }
`
const BlockContentWrapper = styled(SplitSwitcher)`
  color: ${props => props.color};
  height: 100%;
`
const BlockColumnWrapper = styled(ColumnsSwitcher)`
  height: 100%;
  @media (min-width: 936px) {
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: ${props => props.width};
    max-width: 100%;
    box-sizing: border-box;
    align-self: center;
    justify-self: center;
  }
  @media (max-width: 936px) {
    order: 2;
  }
`
const BottomBlock = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 50%;

  img {
    width: 225px;
  }
  @media (max-width: 936px) {
    height: 80%;
    h4 {
      position: absolute;
      top: 0;
    }
  }
`

const BottomBlockText = styled(PadBox)`
  text-shadow: 3px 3px 4px rgba(20, 20, 20, 0.4);
`

const BottomLink = styled(Link)`
  color: #333;
  text-decoration: none;
  text-transform: uppercase;
`

const BlockComponent = ({ color = "#fff", bgColor, fraction, children }) => {
  return (
    <ColorBlock bgColor={bgColor}>
      <BlockContentWrapper fraction={fraction} color={color} switchAt={900}>
        {children}
      </BlockContentWrapper>
    </ColorBlock>
  )
}

const HomeImage = ({ src, alt, width = 350 }) => (
  <img src={src} alt={alt} width={width} style={{ margin: 0 }} />
)

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Arik Parker Web Design" />
      <Stack gutter="none">
        <BlockComponent
          style={{ display: "flex" }}
          bgColor="#255b77"
          fraction="2/3"
        >
          <BlockContent>
            <Stack
              data-aos="fade-right"
              data-aos-delay="600"
              data-aos-duration="1200"
            >
              <h2>Hi, my name is Arik. I design websites, logos, and more.</h2>
              <h2 style={{ color: "#ed7723" }}>How can I help you?</h2>
            </Stack>
          </BlockContent>
          <BlockImageContent
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="1200"
          >
            <ImageWrapper className="shrink">
              <HomeImage src={hex} alt="Arik in a hexagon" width="350px" />
            </ImageWrapper>
          </BlockImageContent>
        </BlockComponent>
        <BlockComponent
          bgColor="#fff"
          fraction="1/3"
          color="#333"
          style={{ display: "flex" }}
        >
          <BlockImageContent
            data-aos="zoom-in"
            data-aos-delay="300"
            data-aos-duration="1200"
          >
            <ImageWrapper className="shrink">
              <HomeImage src={web} alt="Icon of a computer with code" />
            </ImageWrapper>
          </BlockImageContent>
          <BlockContent>
            <Stack
              data-aos="zoom-in"
              data-aos-delay="600"
              data-aos-duration="1200"
            >
              <h2>
                Your website is often the first impression customers get of your
                business.
              </h2>
              <h2 style={{ color: "#ed7723" }}>Shouldn't it be great?</h2>
            </Stack>
          </BlockContent>
        </BlockComponent>
        <BlockComponent
          bgColor="#333"
          fraction="2/3"
          style={{ display: "flex" }}
        >
          <BlockContent>
            <Stack
              data-aos="zoom-in"
              data-aos-delay="600"
              data-aos-duration="1200"
            >
              <h2>
                Your logo is your company's identity, and makes your business
                memorable.
              </h2>
              <h2 style={{ color: "#ed7723" }}>Want it to be awesome?</h2>
            </Stack>
          </BlockContent>
          <BlockImageContent
            data-aos="zoom-in"
            data-aos-delay="300"
            data-aos-duration="1200"
          >
            {" "}
            <ImageWrapper className="spin">
              <HomeImage src={logo} alt="Icon of an abstract logo" />
            </ImageWrapper>
          </BlockImageContent>
        </BlockComponent>
        <BlockComponent
          bgColor="#255b77"
          fraction="1/3"
          style={{ display: "flex" }}
        >
          <BlockImageContent
            data-aos="zoom-in"
            data-aos-delay="300"
            data-aos-duration="1200"
          >
            <ImageWrapper className="squish">
              <HomeImage src={print} alt="Icon of a tri-fold brochure" />
            </ImageWrapper>
          </BlockImageContent>
          <BlockContent>
            <Stack
              data-aos="zoom-in"
              data-aos-delay="600"
              data-aos-duration="1200"
            >
              <h2>
                Business cards, brochures, and marketing materials give you the
                professional look you need.
              </h2>
              <h2 style={{ color: "#ed7723" }}>Ready to impress?</h2>
            </Stack>
          </BlockContent>
        </BlockComponent>
        <ColorBlock bgColor="#fff" color="#333">
          <InlineCluster justify="center">
            <BottomBlockText>
              <h2 style={{ margin: "0", textAlign: "center" }}>
                Where technical ability, creative vision, and fantastic
                communication <span style={{ color: "#ed7723" }}>collide.</span>
              </h2>
            </BottomBlockText>
          </InlineCluster>
          <PadBox style={{ height: "100%" }} padding={["lg", "none"]}>
            <BlockColumnWrapper columns={3} gutter="xl" switchAt={900}>
              <BottomLink to="/portfolio/">
                <BlockContent style={{ flexDirection: "column" }}>
                  <h4>WEB DEVELOPMENT</h4>
                  <BottomBlock
                    data-aos="flip-left"
                    data-aos-delay="200"
                    data-aos-duration="1200"
                  >
                    <ImageWrapper className="shrink">
                      <HomeImage
                        src={web}
                        alt="Arik in a hexagon"
                        width="250px"
                      />
                    </ImageWrapper>
                  </BottomBlock>
                </BlockContent>
              </BottomLink>
              <BottomLink to="/portfolio/">
                <BlockContent style={{ flexDirection: "column" }}>
                  <h4>LOGO DESIGN</h4>
                  <BottomBlock
                    data-aos="flip-left"
                    data-aos-delay="300"
                    data-aos-duration="1200"
                  >
                    <ImageWrapper className="spin">
                      <HomeImage
                        src={logo}
                        alt="Arik in a hexagon"
                        width="250px"
                      />
                    </ImageWrapper>
                  </BottomBlock>
                </BlockContent>
              </BottomLink>
              <BottomLink to="/portfolio/">
                <BlockContent style={{ flexDirection: "column" }}>
                  <h4>PRINT DESIGN</h4>

                  <BottomBlock
                    data-aos="flip-left"
                    data-aos-delay="400"
                    data-aos-duration="1200"
                  >
                    <ImageWrapper className="squish">
                      <HomeImage
                        src={print}
                        alt="Arik in a hexagon"
                        width="250px"
                      />
                    </ImageWrapper>
                  </BottomBlock>
                </BlockContent>
              </BottomLink>
            </BlockColumnWrapper>
          </PadBox>
        </ColorBlock>
      </Stack>
    </Layout>
  )
}

export default IndexPage
